<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('Topics')"/>

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <TopicFilterForm :values="filters" ref="filterForm" slot="filter"/>
          </DataFilter>

          <br/>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template #item.enabled="{ item }">
              {{ item.enabled === true ? enabledStatus.yes : enabledStatus.no }}
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import TopicFilterForm from "../../components/topic/Filter";

export default {
  name: "TopicList",
  servicePrefix: "Topic",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    TopicFilterForm,
  },
  data() {
    return {
      headers: [
        {text: this.$i18n.t("name"), value: "name"},
        {text: this.$i18n.t("enabled"), value: "enabled"},
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
      selected: [],
      filters: {
        __Fexists: {parent: false}
      },
      enabledStatus: {
        yes: this.$i18n.t("yes"),
        no: this.$i18n.t("no"),
      }
    };
  },
  computed: {
    ...mapGetters("topic", {
      items: "list",
    }),
    ...mapFields("topic", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("topic", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearTopicData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    refreshData(){
      this.clearTopicData();
      this.onSendFilter();
    }
  },
};
</script>
